import { Route, Routes } from 'react-router-dom';
import ErrorPage from './components/ErrorPage';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Pages/Home';
import Spacing from './components/Spacing';
import { AuthProvider } from './components/web3/context/AuthContext';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { base, baseSepolia } from 'wagmi/chains';
import { WagmiConfig } from 'wagmi';
import { Toaster } from 'react-hot-toast';
import MyNFTs from './components/Pages/MyNFTs';

const networkType = process.env.REACT_APP_NETWORK_TYPE;
const chains = networkType === 'TESTNET' ? [baseSepolia] : [base];

const projectId = process.env.REACT_APP_PROJECT_ID;

const metadata = {
  name: 'Berumda Shorts Day NFTs',
  description: 'Mint your Bermuda Shorts Day NFTs',
  url: 'https://bsdnfts.com',
  icons: ['https://bsdnfts.com/images/logo.png'],
};

const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  enableAnalytics: true,
});

createWeb3Modal({ wagmiConfig, projectId, chains });

function App() {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <AuthProvider>
          <div>
            <Toaster
              position="bottom-center"
              reverseOrder={false}
              toastOptions={{
                style: {
                  background: '#037FFF',
                  color: '#fff',
                  fontSize: '1.4em',
                },
              }}
            />
          </div>
          <Header />
          <Spacing lg="80" md="80" />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/my-nfts" element={<MyNFTs />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <Footer />
        </AuthProvider>
      </WagmiConfig>
    </>
  );
}

export default App;
