import { Icon } from '@iconify/react'
import React, { useState, useEffect } from 'react'
import Section from '../Section'
import { useAuth } from '../web3/context/AuthContext'
import { mint } from '../web3/BSD NFTs/utils'
import { openPasswordModel } from '../social-wallet/PasswordModal'
import toast from 'react-hot-toast';

export default function MintCard() {
  const [counter, setCounter] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const { loginOption, email, hasPassword, entityInfo, refreshBalance} = useAuth();
  const handelSubtract = () => {
    if(counter>0) {
      setCounter(counter-1)
    }
  }

  const handleAdd = () => {
    if (counter < 20) {
      setCounter(counter + 1);
    }
  };

  const mintNft = async () => {
    if(counter === 0) {
      return toast.error('Please select quantity');
    }
    if(!hasPassword){
      await mint({
        loginOption: loginOption,
        walletId: email,
        walletIndex: entityInfo?.indexWallet,
        network: "BASE",
        walletAddress: entityInfo?.activePublicKey,
        nftAmount: counter,
      })
      refreshBalance();
    } else{ 
      openPasswordModel(handlePassSecureTransfer)
    }
  }

  const handlePassSecureTransfer = async (password, input) => {
    if (password === '') return toast.error('Password is required');
    if (password == null) return;
    try {
      await mint({
        loginOption: loginOption,
        walletId: email,
        walletIndex: entityInfo?.indexWallet,
        network: "BASE",
        walletAddress: entityInfo?.activePublicKey,
        nftAmount: counter,
        password: password,
      })
      refreshBalance();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setTotalPrice((0.05 * counter).toFixed(2))
  }, [counter]);
  return (
    <Section className="cs-mint_secton">
      <Section tag='h2' className="cs-font_22 text-uppercase cs-normal cs-m0 text-center">Get your shorts Now!</Section>
      <Section className="cs-height_25 cs-height_lg_25" />
      <Section className="cs-mint_avatar text-center"><img src="/images/avatar_1.png" alt="" /></Section>
      <Section className="cs-height_50 cs-height_lg_30" />
      <ul className="cs-list cs-style2 cs-mp0 cs-primary_color cs-primary_font">
        <li>
          <Section className="cs-list_left">Quantity / <span className="cs-accent_color">0.05 ETH</span></Section>
          <Section className="cs-list_right">
            <Section className="cs-quantity">
              <button className="cs-quantity_btn cs-center" onClick={handelSubtract}>
                <Icon icon="ic:round-minus" />
              </button>
              {counter}
              <button className="cs-quantity_btn cs-center" onClick={handleAdd}>
                <Icon icon="material-symbols:add-rounded" />
              </button>
            </Section>
          </Section>
        </li>
        <li>
          <Section className="cs-list_left">Total Price</Section>
          <Section className="cs-list_right">{totalPrice} ETH</Section>
        </li>
      </ul>
      <Section className="cs-height_25 cs-height_lg_25" />
      <button className="cs-btn cs-btn_filed cs-accent_btn text-center text-uppercase w-100" onClick={mintNft}><span>Mint Now</span></button>
      <Section className="cs-height_15 cs-height_lg_15" />
    </Section>
  )
}
