import { Alchemy, Network } from "alchemy-sdk";

export const ALCHEMY_CONFIG_DICT = {
    BASE:{
      TESTNET: {
        apiKey: "dxPQSa8iVaWmaKbh-ofdw4PrRspzxMmv",
        network: Network.BASE_SEPOLIA,
      },
      MAINNET: {
        apiKey: "opYbWGVCNugTESzT3fMYisjaQcmFKxvY",
        network: Network.BASE_MAINNET,
      }
    }
  };
  
  export const ALCHEMY_APIS_DICT = {
    BASE:{
      TESTNET: new Alchemy(ALCHEMY_CONFIG_DICT.BASE.TESTNET),
      MAINNET: new Alchemy(ALCHEMY_CONFIG_DICT.BASE.MAINNET),
    }
  };
  
  export const UNIBLOCK_CHAIN_IDS_DICT = {
    BASE: {
      MAINNET: 8453,
      TESTNET: 84532,
    }
} 

export const UNIBLOCK_OPTIONS = {
    method: 'GET', 
    headers: 
        {
            accept: 'application/json', 
            'X-API-KEY': process.env.REACT_APP_UNIBLOCK_API_KEY
        }
    };