import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Modal from '../Modal';
import ModeSwitch from '../ModeSwitch';
import Section from '../Section';
import DropDown from './DropDown';
import './header.scss';
import LoginButtons from '../social-wallet/loginButtons';
import { useAuth } from '../web3/context/AuthContext';
import WalletTooltip from '../social-wallet/tooltip';
export default function Header() {
  const [mobileToggle, setMobileToggle] = useState(false);
  const { isLoggedIn } = useAuth();
  return (
    <>
      <Section
        tag="header"
        className="cs-site_header cs-style1 cs-sticky-header cs-primary_color"
      >
        <Section className="cs-main_header">
          <Section className="container">
            <Section className="cs-main_header_in">
              <Section className="cs-main_header_left">
                <Link to="/" className="cs-site_branding cs-accent_color">
                  <img
                    src="/images/logo.png"
                    alt="Logo"
                    className="cs-hide_dark"
                  />
                  <img
                    src="/images/logo.png"
                    alt="Logo"
                    className="cs-hide_white"
                  />
                </Link>
                {isLoggedIn && (
                  <WalletTooltip title={'See your shorts'}>
                    <Link
                      to="/my-nfts"
                      spy={true}
                      smooth={true}
                      offset={-80}
                      duration={500}
                      className=" ms-5"
                      onClick={() => setMobileToggle(false)}
                    >
                      <img
                        src="/images/mynfts.png"
                        className="mynfts"
                        alt="mynfts"
                      />
                    </Link>
                  </WalletTooltip>
                )}
              </Section>
              {/* {isLoggedIn && (
                <Section className="cs-main_header_center">
                  <Section className="cs-nav">
                    <ul className="cs-nav_list">
                      <li>
                        <Link
                          to="/my-nfts"
                          spy={true}
                          smooth={true}
                          offset={-80}
                          duration={500}
                          className="cs-site_branding"
                          onClick={() => setMobileToggle(false)}
                        >
                          <img src="/images/mynfts.png" alt="mynfts" />
                        </Link>
                      </li>
                    </ul>
                    <span
                      className={
                        mobileToggle
                          ? 'cs-munu_toggle cs-toggle_active'
                          : 'cs-munu_toggle'
                      }
                      onClick={() => setMobileToggle(!mobileToggle)}
                    >
                      <span></span>
                    </span>
                  </Section>
                </Section>
              )} */}
              <Section className="cs-main_header_right">
                <Section className="cs-nav cs-toolbox">
                  <ul
                    className="cs-nav_list justify-content-center"
                    style={{
                      display: `${mobileToggle ? 'flex ' : 'none'}`,
                    }}
                  >
                    <div
                      className={`${
                        mobileToggle ? '' : 'd-flex'
                      } align-items-center mx-4`}
                    >
                      <ModeSwitch />
                      {/* <Modal modalType="connect" btnText="Connect" btnIcon /> */}
                      <LoginButtons />
                      {!isLoggedIn && <w3m-button />}{' '}
                      <Section className="cs-main_header_left">
                        <WalletTooltip
                          title={'Powered by Horus Wallet'}
                          className="mx-4"
                        >
                          <a
                            href="https://horuswallet.com/"
                            target="_blank"
                            className="cs-site_branding cs-accent_color cursor-pointer"
                          >
                            <img src="images/horusLogo.png" alt="Logo" />
                          </a>
                        </WalletTooltip>
                      </Section>
                    </div>
                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? 'cs-munu_toggle cs-toggle_active'
                        : 'cs-munu_toggle'
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </Section>
              </Section>
            </Section>
          </Section>
        </Section>
      </Section>
    </>
  );
}
