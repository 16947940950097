import BSD_NFTS from "./BermudaShortsDayNFTs.json";


export const BSD_CONTRACT_ADDRESSES = {
    TESTNET: "0xE22176193CBa696fDCb4E422A1F043c29C335127",
    // MAINNET: "0x9984E8421e3F8cdC67e78AD2713F09Bb2715a847",
    MAINNET: "0x7079De89F147744DE6993AEBDC5704D9089b79Da",
}

export const BSD_NFTS_CONTRACT_ADDRESS = {
    TESTNET: "0xbcFC53dcF4B6be688c1e44646eAB5010Cc80622B",
    MAINNET: "0x28e6aa926E022B214827878d6934ccBdbe4AedE5",
} 

export const BSD_NFTS_ABI = BSD_NFTS.abi;