import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Modal, Button, Form, CloseButton } from 'react-bootstrap';

export function openPasswordModel(next, input = null, setLoading = null) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  const root = createRoot(div);
  const close = () => {
    root.unmount();
    div.remove();
    document.body.style.overflow = 'auto';
  };
  root.render(
    <PasswordModal
      show={true}
      onPasswordEntered={next}
      close={close}
      inputValue={input}
      setLoading={setLoading}
    />
  );
}

export function PasswordModal(props) {
  const [password, setPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordsMatch(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onPasswordEntered(password, props.inputValue);
    props.close();
  };

  const handleCancel = () => {
    // props.onPasswordEntered(null);
    if (props.setLoading) {
      props.setLoading(false);
    }
    props.close();
  };

  return (
    <Modal
      show={props.show}
      onHide={handleCancel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="password-modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Horus wallet
        </Modal.Title>
        <CloseButton
          onClick={handleCancel}
          style={{ position: 'absolute', right: '20px', top: '20px' }}
        />
      </Modal.Header>
      <Modal.Body>
        <p>
          Your wallet is encyrpted with password, please enter your password
        </p>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formPassword">
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={handlePasswordChange}
              isInvalid={!passwordsMatch}
            />
          </Form.Group>
          <br />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="fugu--portfolio-btn">
          <button className="btn btn-success" onClick={handleSubmit}>
            <span>Submit</span>
          </button>
        </div>
        <div className="fugu--portfolio-btn">
          <button className="btn btn-default" onClick={handleCancel}>
            <span>Close</span>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
