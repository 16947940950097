import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';
import Cta from '../Cta';
import Section from '../Section';
import './footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDiscord,
  faReddit,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons';
import { faX } from '@fortawesome/free-solid-svg-icons';

export default function Footer() {
  return (
    <Section
      tag="footer"
      className="cs-footer_wrap cs-bg"
      style={{ backgroundImage: 'url(/images/footer_bg_1.png)' }}
    >
      <Section className="container">
        <Cta />
      </Section>
      <Section tag="footer" className="cs-footer text-center">
        <Section className="container">
          <Section className="cs-height_100 cs-height_lg_70" />
          <Section className="cs-footer_logo">
            <img
              src="/images/logo.png"
              alt="logo"
              className="cs-hide_dark"
              style={{ height: '140px' }}
            />
            <img
              src="/images/logo.png"
              alt="logo"
              className="cs-hide_white"
              style={{ height: '140px' }}
            />
          </Section>
          <Section className="cs-height_25 cs-height_lg_25" />
          <Section className="cs-social_btns cs-center">
            <Link
              to="https://discord.com/channels/1222592495595163689/1222592496308322377"
              target="_blank"
              className="cs-center cs-primary_color  cs-accent_bg_hover cs-light_bg cs-white_color_hover"
            >
              <FontAwesomeIcon icon={faDiscord} />
            </Link>
            <Link
              to="https://t.me/BermudaShortsProject"
              target="_blank"
              className="cs-center cs-primary_color  cs-accent_bg_hover cs-light_bg cs-white_color_hover"
            >
              <FontAwesomeIcon icon={faTelegram} />
            </Link>
            <Link
              to="https://twitter.com/BermudaShortsDy"
              target="_blank"
              className="cs-center cs-primary_color  cs-accent_bg_hover cs-light_bg cs-white_color_hover"
            >
              <FontAwesomeIcon icon={faX} />
            </Link>
            <Link
              to="https://www.reddit.com/r/BermudaShortsNFTs/"
              target="_blank"
              className="cs-center cs-primary_color  cs-accent_bg_hover cs-light_bg cs-white_color_hover"
            >
              <FontAwesomeIcon icon={faReddit} />
            </Link>
          </Section>
        </Section>
        <Section className="cs-height_45 cs-height_lg_25" />
        <Section className="container">
          <Section className="cs-copyright text-center">
            Copyright © 2024. All Rights Reserved by{' '}
            <span className="cs-primary_font cs-primary_color">BSDNFTs</span>,
            Developed with love by NFTPunks, 204 Devrow Ct, Franklin TN 37064.
          </Section>
        </Section>
        <Section className="cs-height_25 cs-height_lg_25" />
      </Section>
    </Section>
  );
}
