import { ethers } from "ethers";

export const RPC_NODES = {
  BASE:{
    TESTNET: "https://base-sepolia.g.alchemy.com/v2/dxPQSa8iVaWmaKbh-ofdw4PrRspzxMmv",
    MAINNET: "https://base-mainnet.g.alchemy.com/v2/opYbWGVCNugTESzT3fMYisjaQcmFKxvY",
  }
};

export const CHAIN_SCAN = {
  BASE:{
    TESTNET: "https://sepolia.basescan.org/",
    MAINNET: "https://basescan.org/",
  }
}

export const getEthersProvider = (network) => {
  if (!network) {
    throw new Error("Invalid network");
  }

  if (network === "NON_CUSTODIAL") {
    return new ethers.providers.Web3Provider(window.ethereum);
  }

  const options = RPC_NODES[network] 
  const networkType = process.env.REACT_APP_NETWORK_TYPE;
  
  if (!options || !options[networkType]) {
    throw new Error("Invalid network");
  }

  return new ethers.providers.JsonRpcProvider(options[networkType]);
};

