import React from 'react';
import Section from '../Section';
import Spacing from '../Spacing';

const getSizeClassNames = (size) => {
  switch (size) {
    case 'small':
      return {
        number: 'cs-font_42 cs-font_24_sm',
        title: 'cs-font_24 cs-font_22_sm',
      };
    case 'large':
      return {
        number: 'cs-font_72 cs-font_48_sm',
        title: 'cs-font_24 cs-font_22_sm',
      };
    case 'medium':
    default:
      return {
        number: 'cs-font_64 cs-font_36_sm',
        title: 'cs-font_24 cs-font_22_sm',
      };
  }
};

export default function FunFact({
  number,
  title,
  size = 'medium',
  isUniSwap = false,
}) {
  // Default size to 'medium' if not provided
  const { number: numberSizeClass, title: titleSizeClass } =
    getSizeClassNames(size);

  return (
    <Section className="cs-funfact cs-style1">
      <Section
        className={`cs-m0 cs-primary_font cs-primary_color cs-heading_line_height cs-bold ${numberSizeClass}`}
      >
        {number}
      </Section>
      <Spacing lg="5" md="5" />
      <Section
        tag="p"
        className={`cs-m0 cs-heading_line_height ${titleSizeClass}`}
      >
        {title}
      </Section>
      {isUniSwap && (
        <a href="https://app.uniswap.org/pools/120688" target="_blank">
          <img
            src="/images/Uniswap_Logo.png"
            alt="About"
            className="uniswap-img"
          />
        </a>
      )}
    </Section>
  );
}
