import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Modal, Button, Form, CloseButton } from 'react-bootstrap';
import { signOut } from 'firebase/auth';
import { getAuthConfig } from './firebase';
import { toast } from 'react-hot-toast';

export function openNewPasswordModel(next, nextInput) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  const root = createRoot(div);
  const close = () => {
    root.unmount();
    div.remove();
  };

  root.render(
    <NewPasswordModal
      show={true}
      onPasswordEntered={next}
      close={close}
      input={nextInput}
    />
  );
}

export function NewPasswordModal(props) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [createWalletBtnLabel, setCreateWalletBtnLabel] =
    useState('Without Password');
  const [agreements, setAgreements] = useState({
    termsAgreed: false,
    privacyPolicyAgreed: false,
  });
  const [disableBtn, setDisableBtn] = useState(true);

  useEffect(() => {
    validateInputs();
  }, [agreements, passwordsMatch, confirmPassword]);
  // this func responsible to sign out user in case cancel create wallet process by click close btn or click outside the modal
  const socialLogout = async () => {
    const auth = await getAuthConfig();
    signOut(auth);
  };
  const handleCheckboxChange = (checkbox) => {
    setAgreements((prevAgreements) => ({
      ...prevAgreements,
      [checkbox]: !prevAgreements[checkbox],
    }));
  };
  const isAgreementAccept = () => {
    return agreements.termsAgreed && agreements.privacyPolicyAgreed;
  };
  const handlePasswordChange = (event) => {
    if (confirmPassword != '' || event.target.value != '') {
      setCreateWalletBtnLabel('');
    } else {
      setCreateWalletBtnLabel('Without Password');
    }
    setPassword(event.target.value);
    setPasswordsMatch(event.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    if (password != '' || event.target.value != '') {
      setCreateWalletBtnLabel('');
    } else {
      setCreateWalletBtnLabel('Without Password');
    }
    setConfirmPassword(event.target.value);
    setPasswordsMatch(event.target.value === password);
  };

  const handleSubmit = () => {
    if (password === '') {
      toast.error('Please Enter a password or choose without password');
      return;
    }
    if (!passwordsMatch) {
      toast.error("Password doesn't match!");
      return;
    }
    props.onPasswordEntered(password, props.input);
    props.close();
  };

  const handleCancel = async () => {
    props.onPasswordEntered(null);
    await socialLogout();
    props.close();
  };

  const handleWithoutPassword = () => {
    props.onPasswordEntered('', props.input);
    props.close();
  };
  const prepareCreateWallet = (event) => {
    if (!isAgreementAccept()) {
      toast.error(
        `Please Accept ${
          !agreements.termsAgreed ? 'Terms and condition' : 'Privacy Policy'
        }.`
      );
      return;
    }
    event.preventDefault();

    if (createWalletBtnLabel == '') {
      handleSubmit(event);
    } else {
      handleWithoutPassword();
    }
  };

  const validateInputs = () => {
    if (isAgreementAccept() && createWalletBtnLabel == '' && passwordsMatch) {
      setDisableBtn(false);
    } else if (isAgreementAccept() && createWalletBtnLabel != '') {
      setDisableBtn(false);
    } else setDisableBtn(true);
  };

  return (
    <Modal
      show={props.show}
      onHide={handleCancel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="password-modal"
      className="password-modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Would you like to create a wallet?
        </Modal.Title>
        <CloseButton
          onClick={handleCancel}
          className="btn-close-white"
          style={{ position: 'absolute', right: '20px', top: '20px' }}
        />
      </Modal.Header>
      <Modal.Body>
        <p>Would you like to protect your wallet with a password?</p>
        <p>
          Entering the password encrypts your wallet, however, we will not be
          able of restoring your account if you lose the password.
        </p>
        <b />
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formPassword">
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={handlePasswordChange}
              isInvalid={!passwordsMatch}
            />
          </Form.Group>
          <br />
          <Form.Group controlId="formConfirmPassword">
            <Form.Control
              type="password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              isInvalid={!passwordsMatch}
            />
            <Form.Control.Feedback type="invalid">
              Passwords do not match
            </Form.Control.Feedback>
            <div className="pt-3">
              <div className="d-flex align-items-center px-8 mb-4">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  checked={agreements.termsAgreed}
                  onChange={() => handleCheckboxChange('termsAgreed')}
                  className="checkbox"
                />
                <label
                  htmlFor="default-checkbox"
                  className="ms-2 text-antiFlashWhite-100 dark:text-gray-300 text-sm font-medium"
                >
                  <span>I agree to </span>
                  <a
                    href="https://horuswallet.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline"
                  >
                    terms and conditions
                  </a>
                </label>
              </div>
              <div className="d-flex align-items-center px-8 mb-4">
                <input
                  id="checked-checkbox"
                  type="checkbox"
                  checked={agreements.privacyPolicyAgreed}
                  onChange={() => handleCheckboxChange('privacyPolicyAgreed')}
                  className="checkbox"
                />
                <label
                  htmlFor="checked-checkbox"
                  className="ms-2 text-antiFlashWhite-100 dark:text-gray-300 text-sm font-medium"
                >
                  <span>I agree to the </span>
                  <a
                    href="https://horuswallet.com/privacyPolicy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=""
                  >
                    privacy policy
                  </a>
                </label>
              </div>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="fugu--portfolio-btn">
          <button
            className={`btn btn-success ${disableBtn ? 'invalid' : 'valid'}`}
            onClick={prepareCreateWallet}
            disabled={disableBtn}
          >
            <span>Create {createWalletBtnLabel}</span>
          </button>
        </div>
        <div className="fugu--portfolio-btn">
          <button className="btn btn-default" onClick={handleCancel}>
            <span>Cancel</span>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
