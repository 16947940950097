import { BlockchainAPI } from "./BlockchainAPI";
import { NATIVE_TOKENS } from "../utils/Providers";
import { ALCHEMY_APIS_DICT } from "./Service";
import { getNativeTokens } from "../utils/utils";
import { ethers } from "ethers";

export class AlchemyAPI extends BlockchainAPI {
    constructor(network) {
      super(network);
      if (!network || typeof network !== "string") {
        return;
      }
      this.api = ALCHEMY_APIS_DICT[this.network][this.networkType];
    }

    async getNFTs(walletAddress) {
        if(!this.api) return []

        if (!walletAddress || typeof walletAddress !== "string") {
          return []
        }
      
        try {
          // Get user NFTs
          const req = await this.api.nft.getNftsForOwner(walletAddress);
          const nfts = req.ownedNfts;
          console.log("NFTs by Alchemy: ", nfts);
          const resNfts = [];
          for (let nft of nfts) {
            const metadata = {
                contractAddress: nft.contract.address,
                metadata: {
                    image: nft.image.cachedUrl,
                    name: nft.raw.metadata.name || `Bermuda Shorts Day NFT #${nft.tokenId}`,
                    attributes: nft.raw.metadata.attributes,
                },
                name: nft.contract.name || "Bermuda Shorts Day NFT",
                tokenId: nft.tokenId,
            }
            resNfts.push(metadata)
          }
          console.log("All NFTs by Alchemy: ", resNfts);
          return resNfts;
        } catch (error) {
          console.error("Error getting NFTs with Alchemy: ", error);
          // throw new Error("Error getting NFTs with Alchemy");
        }
    }
  }
  