import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { pageTitle } from '../../helper';
import Section from '../Section';
import Spacing from '../Spacing';
import { getNFTs } from '../web3/BSD NFTs/utils';
import { useAuth } from '../web3/context/AuthContext';
import UserNFT from '../MyNFTs/UserNFT';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';

export default function MyNFTs() {
  const { isLoggedIn, entityInfo } = useAuth();
  const [userNFTs, setUserNFTs] = useState();
  const getUserNFTs = React.useCallback(
    async (address) => {
      const nfts = await getNFTs(address);
      nfts && setUserNFTs(nfts);
    },
    [isLoggedIn, entityInfo]
  );

  pageTitle('MY NFTS');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    isLoggedIn && getUserNFTs(entityInfo.activePublicKey);
  }, [isLoggedIn, entityInfo]);
  return (
    <>
      <Spacing lg="95" md="65" />
      <Section className="container">
        <Section className="row">
          {!isLoggedIn ? (
            <h2>Please Login First!</h2>
          ) : userNFTs ? (
            userNFTs.length > 0 ? (
              <div className="row m-10">
                {userNFTs?.map((nft) => (
                  <div className="col-lg-3 mb-5" key={nft.id}>
                    <UserNFT item={nft} key={nft.id} />
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex items-center justify-center h-screen text-lg">
                <span>You Don't have any NFTs Yet</span>
              </div>
            )
          ) : (
            <div className="flex items-center justify-center h-screen">
              <Spinner size={'xl'} />
            </div>
          )}
        </Section>
      </Section>
      <Spacing lg="40" md="20" />
    </>
  );
}
