function UserNFT(props) {
  return (
    <div className="nft-image">
      <img src={props.item.metadata.image} alt="nft" />
      <p className="text-center mt-4">{props.item.metadata.name}</p>
    </div>
  );
}

export default UserNFT;
