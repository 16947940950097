import React, { createContext, useState, useEffect } from 'react'
import { signOut } from 'firebase/auth'
import { getAuthConfig } from '../../social-wallet/firebase'
import { useAccount, useDisconnect } from 'wagmi' 
import { getEthersProvider } from '../utils/Providers';
import { ethers } from 'ethers';
import { getBSDTBalance, getTotalMintedNFTs } from '../BSD NFTs/utils';

interface Wallet {
  activePublicKey: string;
  hasPassword: boolean;
}

interface Blockchain {
  defaultReceivingAddress: string;
  wallets: Wallet[];
}

interface UserWalletsData {
  EVM: Blockchain;
  CASPER: Blockchain;
}
interface IEntityInfo {
  activePublicKey: string,
  userWalletsData?: UserWalletsData | null,
  indexWallet: number
}
//interface of the context object
interface IAuthContextValue {
  isLoggedIn: boolean
  balance: string
  BSDTBalance: string
  entityInfo: IEntityInfo
  email: string;
  token: string;
  loginOption: string;
  loginProvider: string;
  hasPassword: boolean;
  userWalletsData?: UserWalletsData | null;
  isLoading: boolean;
  totalMinted: string;
  setBalance: (balance: string) => void;
  setBSDTBalance: (bsdtBalance: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  setHasPassword: (hasPassword: boolean) => void;
  setEmail: (email: string) => void
  setToken: (token: string) => void
  setLoginOption: (signOption: string) => void;
  setLoginProvider: (loginProvider: string) => void;
  login: () => void
  logout: () => void
  refreshAuth: () => void
  refreshBalance: () => void
  setIsLoggedIn: (isLoggedIn: boolean, pubKey: object | any, loginOption: string | undefined, loginProvider: string | undefined, indexWallet: number, userWallets?: UserWalletsData) => void
}



//inital values for the context object
const AuthContext = createContext<IAuthContextValue>({
  isLoggedIn: false,
  balance: "",
  BSDTBalance: "",
  entityInfo: { activePublicKey: "", userWalletsData: null,indexWallet:0},
  email: '',
  token: '',
  loginOption: '',
  loginProvider: '',
  hasPassword: false,
  isLoading: false,
  totalMinted: "",
  setIsLoading: () => { },
  setBalance: () => { },
  setBSDTBalance: () => { },
 //setUserWalletsData: (walletsData: UserWalletsData | null) => {},
  setHasPassword: () => { },
  setEmail: () => { },
  setToken: () => {},
  setLoginOption: () => { },
  setLoginProvider: () => { },
  login: () => {},
  logout: () => {},
  refreshAuth: () => {},
  refreshBalance: () => { },
  setIsLoggedIn: () => { },
})

const AuthProvider = (props: any) => {
  const { isConnected, address } = useAccount()
  const { disconnect } = useDisconnect()
  const [loggedIn, setLoggedIn] = useState(false);
  const [balance, setBalance] = useState("0");
  const [BSDTBalance, setBSDTBalance] = useState("0");
  const [userWalletsData , setUserWalletsData] = useState<UserWalletsData>()
  const [entityInfo, setEntityInfo] = useState<IEntityInfo>({
    activePublicKey: "",
    userWalletsData: null,
    indexWallet: 0
  })
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [loginOption, setLoginOption] = useState('');
  const [loginProvider, setLoginProvider] = useState('');
  const [hasPassword, setHasPassword] = useState(false);
  const [isLoading ,setIsLoading] = useState(false)
  const [totalMinted, setTotalMinted] = useState("...")
  //a flag to indicate to open or close the dialog box
  /**
  * Updates the login status of the user, if they are logged in or not.
  * @param isLoggedIn - boolean value to indicate if the user is logged in or not.
  * @param pubKey - the public key of the user.
  * @param loginOption - the login option, either evm or walletconnect.
  * @param loginProvider - the login provider, either evm or walletconnect.
  */

async function updateLoginStatus(
    isLoggedIn: boolean,
    pubKey: string | any,
    loginOption: string = "non_custodial",
    loginProvider: string = "wallet_connect",
    indexWallet: number,
    userWallets?: UserWalletsData,
  ) {
      setEntityInfo({ activePublicKey: pubKey, userWalletsData: userWallets, indexWallet })
      setLoginOption(loginOption);
      setLoginProvider(loginProvider);
      setLoggedIn(isLoggedIn)
      setUserWalletsData(userWallets)//this const update to use it in refreshEntity func
      setIsLoading(false)
  }

  // this function refreshes the entity info by setting the entity info to the current public key if logged in

  async function refreshEntityInfo() {
    if (loggedIn) {
      setEntityInfo({ activePublicKey: entityInfo.activePublicKey,indexWallet: entityInfo.indexWallet,  userWalletsData: userWalletsData  })
    }
    else setEntityInfo({ activePublicKey: "" ,indexWallet: 0 })
  }


  //This function is used to get the balance of the entity that is currently logged in. It is used to display the balance in the UI and to check if the entity has enough balance to make a transaction.

  async function refreshEntityBalance() {
    if (entityInfo.activePublicKey !== "" && loggedIn) {
      try {
        const provider = getEthersProvider("BASE");
        const balance = await provider.getBalance(entityInfo.activePublicKey);
        const balanceInEther = ethers.utils.formatUnits(balance, "ether");
        setBalance(balanceInEther);
        const bsdtBalance = await getBSDTBalance(entityInfo.activePublicKey);
        const bsdtBalanceInEther = ethers.utils.formatUnits(bsdtBalance, "ether");
        setBSDTBalance(bsdtBalanceInEther);
        getTotalNFTs()
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function getTotalNFTs() {
    const total_minted = await getTotalMintedNFTs();
    setTotalMinted(total_minted?.toString() || "...")
  }
  

// If the user is connected to the wallet, update the login status
useEffect(() => {
    if (isConnected){
      updateLoginStatus(true, address, "non_custodial", "wallet_connect",entityInfo.indexWallet ,userWalletsData )
      setHasPassword(false)
    } else{
        updateLoginStatus(false, "", "", "",entityInfo.indexWallet, undefined)
      }
}, [isConnected])

useEffect(() => {
  getTotalNFTs()
}, [])


  const login = async () => {
    
  }

const logout = async () => {
    if (!loggedIn) {
      console.log('Already logged out !')
      return false
    }
    if (loginOption === 'custodial') {
      const auth = await getAuthConfig()
      await signOut(auth)
      setEntityInfo({ activePublicKey: "" ,indexWallet:0 })
      setLoginOption('');
      setLoginProvider('');
      setBalance('');
      setLoggedIn(false)
    } else {
      setEntityInfo({ activePublicKey: "",indexWallet:0})
      setLoginOption('');
      setLoginProvider('');
      setLoggedIn(false)
      setBalance('');
      disconnect()
    }
    return true
  }

  useEffect(() => {
    if (isConnected) {
      updateLoginStatus(true, address, "non_custodial", "wallet_connect",entityInfo.indexWallet ,userWalletsData )
      refreshEntityBalance();
    }
  }, [address])




  
  useEffect(() => {
    if (loginOption === 'custodial') {
      console.log(
        '\n\nAuth details changed\n',
        {
          isLoggedIn: loggedIn,
          signType: loginOption,
          pubKey: entityInfo.activePublicKey,
          user: email,
          wallet: entityInfo.indexWallet,
          hasPassword: hasPassword,
        },
        '\n\n\n',
      )
      refreshEntityBalance();
    } else if(loginOption === 'non_custodial') {
      console.log(
        '\n\nAuth details changed\n',
        {
          isLoggedIn: loggedIn,
          signType: loginOption,
          pubKey: entityInfo.activePublicKey,
        },
        '\n\n\n',
      )
      refreshEntityBalance();
    }
  }, [loggedIn, entityInfo?.activePublicKey, address])

  const contextValue: IAuthContextValue = {
    isLoggedIn: loggedIn,
    balance: balance,
    BSDTBalance: BSDTBalance,
    entityInfo: entityInfo,
    email: email,
    token: token,
    loginOption: loginOption,
    loginProvider: loginProvider,
    hasPassword: hasPassword,
    userWalletsData: userWalletsData,
    isLoading: isLoading,
    totalMinted: totalMinted,
    setIsLoading: setIsLoading,
    setBalance: setBalance,
    setBSDTBalance: setBSDTBalance,
    setHasPassword: setHasPassword,
    setEmail: setEmail,
    setToken: setToken,
    setLoginOption: setLoginOption,
    setLoginProvider: setLoginProvider,
    login: login,
    logout: logout,
    refreshAuth: refreshEntityInfo,
    refreshBalance: refreshEntityBalance,
    setIsLoggedIn: updateLoginStatus,
  }

  return <AuthContext.Provider value={contextValue} {...props} />
}

const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth }
