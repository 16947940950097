import { toast } from 'react-toastify';
import { getNetwork } from '@wagmi/core';
import { ethers } from 'ethers';
import { signTransaction, writeToContractByWagmi } from '../utils/utils';
import { BSD_CONTRACT_ADDRESSES, BSD_NFTS_ABI, BSD_NFTS_CONTRACT_ADDRESS } from './constants';
import { getEthersProvider } from '../utils/Providers';
import { UniblockAPI } from '../BlockchainAPIs/UniblockAPI';
import { AlchemyAPI } from '../BlockchainAPIs/AlchemyAPI';

export const mint = async (params) => {
    if(!params.walletAddress)
        return toast.error("Please Connect Wallet First!");
    if(params.loginOption === "custodial") {
        await mintCustodial(params);
    } else if(params.loginOption === "non_custodial"){
        await mintNonCustodial(params);
    } else {
        toast.error("Please Connect Wallet First!");
    }
}

const mintCustodial = async (params) => {
    const networkType = process.env.REACT_APP_NETWORK_TYPE;
    const value = ethers.utils.parseEther((0.05 * params.nftAmount).toString()) ;
    try{
        await signTransaction(
            params.walletId,
            params.walletIndex,
            params.network,
            params.password,
            [
                {
                    contractAddress: BSD_CONTRACT_ADDRESSES[networkType],
                    abi: BSD_NFTS_ABI,
                    functionName: "mint",
                    params: [params.nftAmount],
                    value: value
                }
            ],
            "Minting Your Bermuda Shorts Day NFT"
        );
    } catch (error) {
        console.log(error);
        toast.error("Minting Failed!");
    }

}

const mintNonCustodial = async (params) => {
    const {chain} = getNetwork();
    if( chain === undefined)
        return toast.error("Please Connect Wallet First!");
    const chainId = chain.id;
    if( chainId !== 8453 && chainId !== 84532)
        return toast.error("Please Connect to Base!");
    const networkType = process.env.REACT_APP_NETWORK_TYPE;
    const provider = getEthersProvider("BASE");
    const balance = await provider.getBalance(params.walletAddress);
    if(ethers.utils.formatUnits(balance, "ether") < 0.05)
        return toast.error("Insufficient Balance!");

    try{
        const value = ethers.utils.parseEther((0.05 * params.nftAmount).toString()) ;
        await writeToContractByWagmi(
            {
                address: BSD_CONTRACT_ADDRESSES[networkType],
                abi: BSD_NFTS_ABI,
            },
            {
                functionName: "mint",
                args: [params.nftAmount],
                value: value
            },
            "BASE",
            "Mining Your Bermuda Shorts Day NFT",
        );
    } catch (error) {
        console.log(error);
        toast.error("Minting Failed!");
    }
}

export const getTotalMintedNFTs = async () => {
    const networkType = process.env.REACT_APP_NETWORK_TYPE;
    const provider = getEthersProvider("BASE");
    try{
        const contract = new ethers.Contract(
            BSD_CONTRACT_ADDRESSES[networkType],
            BSD_NFTS_ABI,
            provider
        );
        const totalMinted = await contract.totalMinted();
        return totalMinted;
    } catch (error) {
        console.log(error);
    }
}

export const getBSDTBalance = async (walletAddress) => {
    const networkType = process.env.REACT_APP_NETWORK_TYPE;
    const provider = getEthersProvider("BASE");
    try{
        const contract = new ethers.Contract(
            BSD_CONTRACT_ADDRESSES[networkType],
            BSD_NFTS_ABI,
            provider
        );
        const balance = await contract.balanceOf(walletAddress);
        console.log(balance);
        return balance;
    } catch (error) {
        console.log(error);
    }
}

export const getNFTs = async (walletAddress) => {
    const alchemy = new AlchemyAPI("BASE");
    const nfts = await alchemy.getNFTs(walletAddress);
    const filteredNFTs = nfts.filter(nft => nft.contractAddress === BSD_NFTS_CONTRACT_ADDRESS[process.env.REACT_APP_NETWORK_TYPE]);
    return filteredNFTs;
}

export const addToMetaMask = () =>{
    const networkType = process.env.REACT_APP_NETWORK_TYPE;
    const tokenAddress = BSD_CONTRACT_ADDRESSES[networkType];
    const tokenSymbol = 'BSD';
    const tokenDecimals = 18;
    const tokenImage = 'http://localhost:3000/images/logo.png';
    
    try {
      if (window.ethereum) {
        window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', 
            options: {
              address: tokenAddress, 
              symbol: tokenSymbol, 
              decimals: tokenDecimals, 
              image: tokenImage, 
            },
          },
        }).then((success) => {
          if (success) {
            toast.success(`Added ${tokenSymbol} to wallet!`);
          } else {
            toast.error(`Failed to add ${tokenSymbol} to wallet!`);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }