import { Icon } from '@iconify/react';
import React from 'react';
import Slider from 'react-slick';
import Section from '../Section';
import CardStype2 from '../Card/CardStype2';

export default function RoadmapSlider() {
  const data = [
    {
      phase: 'Phase 1',
      title: 'BSD!',
      subtitle: 'We launch on Bermuda Shorts Day, this is just the start!',
    },
    {
      phase: 'Phase 2',
      title: 'NFTs ready to mint!',
      subtitle:
        'A beautiful collection of 10K shorts are released for the community!',
    },
    {
      phase: 'Phase 3',
      title: 'Impact!',
      subtitle: `30% of the revenues is automatically donated to the  <a   href="https://bermudafoundation.org/"
        className='underline text-blue pointer' target="_blank">Bermuda Foundation <img src="/images/bsdFoundation.png" className='ms-2'/></a>`,
    },
    {
      phase: 'Phase 4',
      title: 'Party on the beach!',
      subtitle: `Our goal is to support Bermuda, we would love to throw a cool party on the beach for the next BSD, we want to invite all of you!<br/> <a   href="https://t.me/BermudaShortsProject"
       className='underline text-blue pointer' target="_blank">Send in suggestions!</a>`,
    },
  ];
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="material-symbols:chevron-left-rounded" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="material-symbols:chevron-right-rounded" />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1285,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="cs-gap-24 cs-awwow_style_1 cs-type1">
      {data.map((item, index) => (
        <Section key={index}>
          <CardStype2
            cardNumber={index + 1}
            phase={item.phase}
            title={item.title}
            subtitle={item.subtitle}
          />
        </Section>
      ))}
    </Slider>
  );
}
