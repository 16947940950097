// Import the functions you need from the SDKs you need
import { initializeApp, getus } from "firebase/app";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { setAxiosToken } from "./AxiosToken";
export function getAuthConfig() {
  // Call AWS API to get firebase config
  // The only place need to change is the API url as it's specific for each project
  // const response = await axios.get("<Secure-AWS-API>").catch((error) => {
  //   console.log(error);
  // });
  // Get firebase config
  //  const firebaseConfig = await response.data;
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_HORUS_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_HORUS_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_HORUS_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_HORUS_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_HORUS_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_HORUS_APP_ID,
  };
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // Get auth
  const auth = getAuth(app);
  return auth;
}
export async function refreshFirebaseToken() {
  try {
    const auth = getAuth();

    if (auth.currentUser) {
      const idToken = await auth.currentUser.getIdToken(true);
      // console.log('Refreshed ID token:', idToken);
      return idToken;
    } else {
      console.error('No current user found');
      return null;
    }
  } catch (error) {
    console.error('Error refreshing ID token:', error);
    return null;
  }
}
