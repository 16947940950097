export class BlockchainAPI {
    constructor(network) {
      if (new.target === BlockchainAPI) {
        throw new TypeError("Cannot construct BlockchainAPI instances directly");
      }
      this.network = network;
      this.networkType = process.env.REACT_APP_NETWORK_TYPE;
    }
  
    async getNFTs(walletAddress) {
      throw new Error("Method 'getNFTs()' must be implemented.");
    }
  }